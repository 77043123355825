import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Carousel } from '@sbt-web/carousel';
import { AdHpCard, AdHpCardProps } from './Card';
import '@sbt-web/carousel/style';
import styles from './index.module.scss';

type Props = {
  title: string;
  items: Array<AdHpCardProps>;
  cardsWithCTA?: boolean;
  classes?: Array<string>;
};

const AdHpCarousel = ({ title, items, cardsWithCTA, classes = [] }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [media, setMedia] = useState<'desktop' | 'mobile'>('desktop');

  useEffect(() => {
    const media = window.matchMedia('(min-width: 768px)');
    setMedia(media.matches ? 'desktop' : 'mobile');

    // Define the listener as a separate function to avoid recreating it on each render
    const updateMedia = () => setMedia(media.matches ? 'desktop' : 'mobile');

    // Use 'change' instead of 'resize' for better performance
    media.addEventListener('change', updateMedia);

    // Cleanup function to remove the event listener
    return () => media.removeEventListener('change', updateMedia);
  }, []);

  // make it visible
  useEffect(() => {
    if (items.length) {
      setIsVisible(true);
    }
  }, [items]);

  if (!items.length) {
    return null;
  }

  return (
    <section
      className={cn(
        styles['wrapper'],
        styles[`with-${items.length}`],
        classes,
        {
          [styles['ready']]: isVisible,
          [styles['without-cta']]: !cardsWithCTA,
        }
      )}
    >
      <h5 className={`headline-6 ${styles['title']}`}>{title}</h5>
      <Carousel
        cssClass={cn(
          styles['carousel'],
          'slider-nav-round',
          'slider-nav-visible'
        )}
        currentIndex={0}
        showButtons={media === 'desktop' && items.length > 3}
        listItems={items.map((itemProps) => (
          <li key={itemProps.ad.urn}>
            <AdHpCard {...itemProps} />
          </li>
        ))}
        labelArrows={{
          left: 'Indietro',
          right: 'Avanti',
        }}
      />
    </section>
  );
};

export { AdHpCarousel, AdHpCard };
